$dark-gray: #425363;
$black-gray: #263645;
$gray: #98a3ad;
$cloudy-gray: #c8d1d9;

$red: #ec6878;
$green: #39c889;
$blue: #00b0ff;

$deep-blue: #396eff;

$easing: cubic-bezier(.25, .1, .25, 1);

$primary: #2a7de2;
$secondary: #ec6878;
$header: #2a7de2;
$side-bar: #425363;
$category-text: #98a3ad;
$border: #e7eaec;