@import "../../styles/variables";

.analytics {
  display: flex;
  flex-direction: column;

  .graph {
    width: 100%;
    margin-bottom: 20px;
  }

  .graph, .spreadSheet {
    background: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(33, 33, 33, .15);
    border-radius: 4px;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    min-height: 626px;
    position: relative;
  }

  .frequencies {
    display: flex;
    margin-bottom: 15px;

    .frequency {
      display: flex;
      align-items: center;
      margin-right: 25px;
      cursor: pointer;

      .shape {
        border-radius: 4px;
        color: rgba(#fff, 0);
        margin-right: 8px;
        background: #efefef;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }

      .name {
        font-size: 12px;
        font-weight: bold;
      }

      &.isSelected {
        .shape {
          color: rgba(#fff, 1);
          background: $blue;
          background: #00b0ff;
        }
      }
    }
  }
}
