@import '../../styles/variables';

.analyticsGraph {
  display: flex;
  flex-direction: column;
  position: relative;

  .canvas {
    height: 600px;
  }

  .selectedColumn {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    box-shadow: 3px 3px 24px 0 rgba(#000, .2);
    border-radius: 4px;
    z-index: 99;
    padding: 20px;
    left: 0;
    top: 0;
    pointer-events: none;

    .time {
      font-size: 12px;
      margin-bottom: 15px;
      color: $gray;
    }

    .company {
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-right: 10px;
        min-width: 70px;

        .shape {
          width: 12px;
          min-width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
        }

        .name {
          font-size: 12px;
          line-height: 12px;
          min-width: 140px;
        }
      }

      .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .total, .intersection {
          font-size: 12px;
          top: -1px;
          position: relative;
          line-height: 1.5;
          user-select: none;
          transition: opacity 0.15s ease-in-out;
          text-align: right;
        }

        .total {
          margin-right: 10px;
        }

        .intersection {
          color: $gray;
          font-size: 11px;
        }
      }
    }
  }

  .companies {
    display: flex;
    padding-left: 60px;

    .company {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
      padding: 7px;
      border-radius: 2px;

      &.isSelected {
        .shape {
          opacity: 1;
        }

        .name {
          opacity: 1;
        }
      }

      .shape {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        border-radius: 50%;
        opacity: 0.3;
        transition: opacity 0.15s ease-in-out;
      }

      .name {
        font-size: 12px;
        line-height: 12px;
        user-select: none;
        opacity: 0.3;
        transition: opacity 0.15s ease-in-out;
      }
    }
  }
}
