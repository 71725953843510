.dateFilter {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif', serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
  }

  position: relative;
  display: flex;
  align-items: center;
  
  button {
    background: none;
    border: none;
  }

  .dateWrapper {
    margin-bottom: 12px;
    width: 120px;
    position: relative;
    label {
      position: absolute;
      top: -14px;
      left: 0;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.54);
    }
    .date {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #425363;
      height: 30px;

      .writeInput {
        border: none;
        padding: 5px;
        background: none;
      }

      .dateText {
        font-size: 14px;
        letter-spacing: 1.5px;
        font-weight: bold;
        color: #425363;
        margin-right: 14px;
      }
    }
  }
  .datePickerWrapper {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
  }
}
