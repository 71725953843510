.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 40px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    height: 100%;
    width: 25%;
    border-radius: 2px;
    background: #00b0ff;
    animation-fill-mode: forwards;

    &.index0 {
      margin-right: 10%;
      animation: flash .6s ease-in-out infinite;
    }

    &.index1 {
      margin-right: 10%;
      animation: flash .6s ease-in-out infinite;
      animation-delay: .15s;
    }

    &.index2 {
      animation: flash .6s ease-in-out infinite;
      animation-delay: .3s;
    }
  }
}

@keyframes flash {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }

  50% {
    opacity: .3;
    transform: scaleY(.7);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
