@import '../../styles/variables';

.datePicker {
  display: inline-block;
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.13);
  .days {
    background-color: #f5f5f5;
    width: 304px;
    padding: 8px 12px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    .day {
      font-size: 13px;
      height: 28px;
      line-height: 28px;
    }
  }
  .calendarMask {
    width: 304px;
    height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 12px;
    border-bottom: 1px solid $border;
  }
  .calendarContent {
    position: relative;
  }
  .yearWrapper {
    font-size: 12px;
    padding: 12px 0 12px 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
  }
}
