@import "../../styles/variables";

.spreadSheet {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table {
    width: 100%;
    border-collapse: collapse;

    th {
      padding: 15px;
      color: #fff;
      background: $dark-gray;
      border-right: solid $black-gray 1px;
      font-size: 12px;
      white-space: nowrap;
    }

    td {
      padding: 10px;
      font-size: 12px;
      border-bottom: solid 1px rgba( 0, 0, 0, .1);
      text-align: center;
    }
  }

  .header {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
  }

  .date {
    text-align: center;
    font-size: 12px;

    &.isOdd {
      background: #efefef;
    }
  }

  .download {
    font-size: 12px;
    box-shadow: inset 0 0 0 1px #1d6f42;
    color: #1d6f42;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    border-radius: 2px;
    transition: color .3s, background .3s;
    position: relative;
    background: #fff;
    border: none;

    &:hover {
      background: #1d6f42;
      color: #fff;
    }

    svg {
      font-size: 14px;
      margin-right: 2.5px;
      top: 1px;
      position: relative;
    }
  }
}
