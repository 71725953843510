.toastItem {
  position: absolute;
  white-space: nowrap;
  right: 0;
  bottom: 0;
  display: flex;
  box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  align-items: center;
  justify-items: center;
  height: 40px;
  background: #ffffff;
  padding: 7px;
  box-sizing: border-box;
  padding-right: 12px;
  border: solid #ddd 1px;

  .bar {
    width: 4px;
    height: 100%;
    display: flex;
    margin-right: 15px;
    border-radius: 100px;
  }

  .icon {
    display: flex;
    font-size: 18px;
    margin-right: 7px;
  }

  .text {
    display: flex;
    line-height: 1;
    font-size: 14px;
    color: #3e3e3e;
    letter-spacing: -.5px;
    position: relative;
  }

  &.information {
    .bar {
      background: #1d72f3;
    }
  }

  &.success {
    .bar {
      background: #2bde3f;
    }
  }

  &.warning {
    .bar {
      background: #ffc007;
    }
  }

  &.failure {
    .bar {
      background: #e74c3c
    }
  }
}
