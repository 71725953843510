@import '../../styles/variables';

.loginForm {
  width: 300px;
  height: 360px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 16px 24px rgba(0,0,0,.2);

  .header {
    display: flex;
    justify-content: center;
    min-height: 184px;
  }

  .form {
    display: flex;
    padding: 20px;
    flex-direction: column;

    .formControl {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: solid #efefef 2px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: $black-gray;
        bottom: -2px;
        will-change: width;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: width .3s $easing, opacity .3s $easing;
      }

      &.isFocus {
        &::after {
          width: 100%;
          opacity: 1;
        }
      }

      .icon {
        padding: 0 8px;
        display: flex;
        color: $black-gray;
        opacity: .75;
      }

      .input {
        width: 100%;
        border: none;
        background: none;
        height: 30px;
        position: relative;
      }
    }

    .actions {
      padding-top: 5px;
      display: flex;
      justify-content: flex-end;

      .submitButton {
        width: 100px;
        height: 40px;
        background: #efefef;
        border: none;
        border-radius: 4px;
        color: #fff;
        position: relative;
        background: $black-gray;
      }
    }
  }
}
