@import 'src/styles/variables';

.sidebar {
  background-color: $dark-gray;
  width: 200px;
  height: calc(100vh - 50px);
  overflow: auto;
  position: fixed;
  left: 0;
  top: 50px;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .today {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 0 20px;
    height: 50px;
    align-items: center;

    p {
      font-size: 14px;
    }
  }

  .routeGroups {
    display: flex;
    flex-direction: column;

    .routeGroup {
      display: flex;
      flex-direction: column;

      .groupName {
        font-size: 10px;
        color: #98a3ad;
        display: flex;
        padding-left: 20px;
        align-items: center;
        height: 30px;

        svg {
          font-size: 14px;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }
      }

      .link {
        display: flex;
        padding-left: 20px;
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        height: 40px;
        align-items: center;

        &:hover, &.isActivated {
          color: $blue;
          background: rgba(0, 0, 0, .1);
        }
      }
    }
  }
}
