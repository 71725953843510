@import '../../styles/variables';

.header {
  height: 50px;
  width: 100%;
  background: #396eff;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9999;

  .logo {
    width: 100px;
    height: 100%;
    background: url(../../images/logo.svg) no-repeat center;
  }

  .logout {
    border-radius: 4px;
    border: none;
    color: $deep-blue;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: #ffffff;
    position: absolute;
    right: 20px;
  }
}
