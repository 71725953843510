@import '../../styles/variables';

table {
  width: 100%;

  tr {
    td {
    }
  }
  .date {
    height: 38px;
    text-align: center;
    font-size: 12px;
    position: relative;
    z-index: 1;
    line-height: 38px;
    &:hover {
      cursor: pointer;
    }

  }
  .startDateText {
    color: white;
  }

  .startDate {
    background-color: $primary;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 28px;
    height: 28px;
    z-index: -1;
  }
}
